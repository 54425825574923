// RegistrationLayout
import Icon from 'components/common/Icon';
import useWindowSize, { BREAKPOINTS } from 'hooks/useWindowSize';
import React, { PropsWithChildren } from 'react';
import { NextPageWithLayout } from 'types/page';
import styles from './RegistrationLayout.module.sass';
import Toolbar from 'components/containers/RegistrationLayout/Toolbar';

const RegistrationLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { width } = useWindowSize();
  const greaterThanMD = width > BREAKPOINTS.MD;
  return (
    <div className={styles.layoutRoot}>
      {greaterThanMD && (
        <div className={styles.sideMenuRoot}>
          <div className={styles.backdrop} />
          <div className={styles.menuContent}>
            <div className={styles.listContainer}>
              <h4 className={styles.listTitle}>
                Creators, <br /> welcome home
              </h4>
              <ListItem text="Daily brand partnership opportunities" />
              <ListItem text="Make cash for shooting content" />
              <ListItem text="Grow your creative career" />
              <ListItem text="Beginner or seasoned creators welcome" />
            </div>
          </div>
        </div>
      )}
      <div className={styles.layoutContent}>
        <Toolbar />
        {children}
      </div>
    </div>
  );
};

export default RegistrationLayout;

const ListItem: React.FC<{ text: string }> = ({ text }) => (
  <div className={styles.listItem}>
    <Icon name="check-circle" className={styles.icon} />
    <p className={styles.listText}>{text}</p>
  </div>
);

export const getRegistrationLayout: NextPageWithLayout['getLayout'] = (
  children
) => <RegistrationLayout>{children}</RegistrationLayout>;
