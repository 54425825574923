import React from "react";
import { NextPageWithLayout } from "types/page";
import Loader from "./index";
const PageLoader: NextPageWithLayout = () => (
  <div className="page-loader">
    <Loader />
  </div>
);

export default PageLoader;
