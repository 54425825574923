import React, { useEffect } from 'react';
import styles from './RegistrationToolbar.module.sass';
import { useRouter } from 'next/router';
import ROUTES from 'config/routes';
import { useAuth } from 'hooks/useAuth';
import Bugsnag, { addErrorMetaData } from 'services/bugsnag';
import { NextPageWithLayout } from 'types/page';

const RegistrationToolbar: React.FC = () => {
  const router = useRouter();
  const { isLoading, authProfile, logout } = useAuth();

  useEffect(() => {
    if (!isLoading && typeof authProfile !== 'undefined') {
      if (typeof authProfile.user !== 'undefined') {
      } else {
        Bugsnag.notify(
          'Logged In without id',
          addErrorMetaData('user', { authProfile })
        );
      }
    }
  }, [isLoading, authProfile]);

  const handleLogout = () => {
    logout();
  };

  return (
    <div className={styles.header}>
      <img height={48} src="/images/trend-logo.svg" alt="trend-logo" />
      {router.pathname === ROUTES.ApplicationStatus.getPath() && (
        <p className={styles.signOut} onClick={handleLogout}>
          Sign Out
        </p>
      )}
    </div>
  );
};

export default RegistrationToolbar;

export const getRegistrationToolbarLayout: NextPageWithLayout['getLayout'] = (
  children
) => (
  <>
    <RegistrationToolbar />
    {children}
  </>
);
