import {
  CREATOR_CATEGORIES,
  CREATOR_SKILLS,
  SOCIAL_PROVIDERS,
} from 'constants/registration';
import { MediaItem } from './content';
import { User } from './user';

export type TagType = {
  id: string;
  name: string;
  label: string;
};

// TODO: Update the name of this or update the JOB_TYPES enum
export type JobType = 'content' | 'social';
export type CreatorJobTypes = Record<JobType, boolean>;

export type CreatorCategory = (typeof CREATOR_CATEGORIES)[number];

export const isCreatorCategory = (
  category: any
): category is CreatorCategory => {
  return (
    typeof category === 'string' && CREATOR_CATEGORIES.includes(category as any)
  );
};

export type CreatorSkill = (typeof CREATOR_SKILLS)[number];

export const isCreatorSkill = (skill: any): skill is CreatorSkill => {
  return typeof skill === 'string' && CREATOR_SKILLS.includes(skill as any);
};

export type InfluencerPortfolio = {
  photos: string[];
  videos: string[];
};
export type CreatorPortfolio = Array<MediaItem>;

export type Address = Record<
  'line1' | 'line2' | 'city' | 'postalCode' | 'state' | 'country',
  string
>;

export enum APPLICATION_STATUSES {
  NEW = 'new',
  SUBMITTED = 'submitted',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum GENDERS {
  M = 'male',
  F = 'female',
  O = 'other',
}

export const isGender = (gender: any): gender is GENDERS => {
  return gender === GENDERS.M || gender === GENDERS.F || gender === GENDERS.O;
};

export enum AGE_RANGES {
  A_18_24 = '18-24',
  A_25_31 = '25-31',
  A_32_44 = '32-44',
  A_45_PLUS = '45+',
}

export const isAgeRange = (str: any): str is AGE_RANGES => {
  return (
    str === AGE_RANGES.A_18_24 ||
    str === AGE_RANGES.A_25_31 ||
    str === AGE_RANGES.A_32_44 ||
    str === AGE_RANGES.A_45_PLUS
  );
};

export type SocialLink = {
  url: string;
  social: SOCIAL_PROVIDERS;
};

export interface Creator {
  _id: string;
  address: Address; // Should these be in user?
  gender?: GENDERS;
  ageRange?: AGE_RANGES;
  payPalEmail: string;
  socials: Partial<Record<SOCIAL_PROVIDERS, string>>;
  portfolio: CreatorPortfolio;
  jobTypes: CreatorJobTypes;
  applicationStatus: APPLICATION_STATUSES;
  //
  socialCategories: string[];
  contentCategories: string[];
  //
  username: string;
  about: string;
  equipment: string[];
  environment: string[];
  phoneNumber: string;
}

export const isCreator = (creator: any): creator is Creator => {
  if (
    creator &&
    typeof creator === 'object' &&
    Object.keys(creator).includes('_id')
  ) {
    return true;
  }
  return false;
};

export type RegistrationForm = Pick<
  Creator,
  | 'address'
  | 'ageRange'
  | 'payPalEmail'
  | 'socials'
  | 'portfolio'
  | 'jobTypes'
  | 'gender'
  | 'phoneNumber'
> & {
  creatorCategories: Partial<Record<CreatorCategory, boolean>>;
  creatorSkills: Partial<Record<CreatorSkill, boolean>>;
} & Pick<
    User,
    '_id' | 'firstName' | 'lastName' | 'profilePicture' | 'outsideUS'
  >;
