import React, { PropsWithChildren, useEffect, useState } from 'react';
import PageLoader from 'components/common/Loader/PageLoader';
import { useAuth } from 'hooks/useAuth';
import { useRouter } from 'next/router';
import { arraysHaveMatch } from 'utils/array';
import { User } from 'types/user';

interface WithAuthenticationRequiredOptions {
  LoadingComponent: React.FC;
  returnTo: string;
  postAuthRedirect: string;
  permissions: User['permissions'];
}

const DefaultLoadingComponent: React.FC = () => <PageLoader />;

const AuthPageProtection: React.FC<
  PropsWithChildren<{
    options?: Partial<WithAuthenticationRequiredOptions>;
  }>
> = ({ children, options = {} }) => {
  const router = useRouter();
  const [routeIsAuthenticated, setRouteAuthenticated] = useState(false);
  const { isAuthenticated, isLoading, authProfile } = useAuth();
  const {
    returnTo = '/',
    LoadingComponent = DefaultLoadingComponent,
    postAuthRedirect = router.query.redirect,
    permissions = [],
  } = options;

  if (typeof postAuthRedirect === 'string') {
    setAuthRedirect(postAuthRedirect);
  }

  useEffect(() => {
    if (isLoading || routeIsAuthenticated) {
      return;
    }

    if (!isAuthenticated) {
      router.push(returnTo);
    } else if (
      permissions.length > 0 &&
      !arraysHaveMatch(permissions, authProfile!.user.permissions || [])
    ) {
      window.alert('Error: You do not have permissions to access this page');
      router.back();
    } else {
      setRouteAuthenticated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoading,
    isAuthenticated,
    // router,
    // routeIsAuthenticated,
    permissions,
    returnTo,
    authProfile,
  ]);

  return routeIsAuthenticated ? <>{children}</> : <LoadingComponent />;
};

export default AuthPageProtection;

export const setAuthRedirect = (authRedirect: string) => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem('post-auth-redirect', authRedirect);
  }
};

export const getAuthRedirect = () => {
  if (typeof window !== 'undefined') {
    return window.sessionStorage.getItem('post-auth-redirect');
  }
  return null;
};
