import { IconName } from 'components/common/Icon/Icons';
import { User } from 'types/user';

export interface Route {
  name: string;
  getPath: (id?: string) => string;
  icon?: IconName;
  permissions?: User['permissions'];
}

export type Pages =
  | 'Home'
  | 'Registration'
  | 'ApplicationStatus'
  | 'AppLanding'
  | 'BrowseListings'
  | 'MyJobs'
  | 'Messages'
  | 'Profile'
  | 'PublicProfile'
  | 'Help'
  | 'AccountSettings'
  | 'MyJobDetails';

// type RouteConfig = Record<Pages, Route>;
type NavConfig = Record<Pages, Required<Route>>;

// TODO: Add a way to use query parameters, so that it's consistent across function usage
export const APP_NAV_ROUTES: Pick<
  NavConfig,
  'AppLanding' | 'BrowseListings' | 'MyJobs' | 'Profile' | 'Messages'
> = {
  AppLanding: {
    name: 'Home',
    getPath: () => '/landing',
    icon: 'home',
    permissions: ['admin'],
  },
  BrowseListings: {
    name: 'Browse Listings',
    getPath: () => '/listings',
    icon: 'list',
    permissions: ['admin'],
  },
  MyJobs: {
    name: 'My Jobs',
    getPath: () => '/my-jobs',
    icon: 'document',
    permissions: ['admin'],
  },
  Messages: {
    name: 'Messages',
    getPath: () => '/messages',
    icon: 'message',
    permissions: ['admin'],
  },
  Profile: {
    name: 'Profile',
    getPath: () => `/profile`,
    icon: 'profile-circle',
    permissions: [],
  },
};

export const HELP_ROUTES: Pick<NavConfig, 'Help' | 'AccountSettings'> = {
  Help: {
    name: 'Help & Getting Started',
    getPath: () => '/help',
    icon: 'help',
    permissions: ['admin'],
  },
  AccountSettings: {
    name: 'Account Settings',
    getPath: () => '/settings',
    icon: 'settings',
    permissions: [],
  },
};

const ROUTES = {
  ApplicationStatus: {
    name: 'Application Status',
    getPath: () => '/application-status',
  },
  Registration: {
    name: 'Registration',
    getPath: () => '/registration',
  },
  Home: {
    name: 'Home',
    getPath: () => '/',
    icon: 'home',
  },
  ...APP_NAV_ROUTES,
  ...HELP_ROUTES,
  MyJobDetails: {
    name: 'My Job Details',
    getPath: (id: string) => `/my-jobs/${id}`,
    icon: 'document',
    permissions: ['admin'] as User['permissions'],
  },
  PublicProfile: {
    name: 'Profile',
    // NOTE: Use encodeURIComponent to escape special characters in usernames
    getPath: (userId: string) => `/profile/${encodeURIComponent(userId)}`,
    icon: 'home',
  },
} as const;

export default ROUTES;
