import { AGE_RANGES, isAgeRange } from 'types/creator';
import { isURL } from 'utils/validators';
import * as Yup from 'yup';

export const AGE_RANGE_VALIDATOR = Yup.mixed<AGE_RANGES>()
  .required('Age Range is required')
  .test('valid-age-range', 'Invalid Age Range', (value) => isAgeRange(value));

export const ADDRESS_VALIDATOR = Yup.object().shape({
  line1: Yup.string().required('Address is required'),
  line2: Yup.string().optional(),
  city: Yup.string().required('City is required'),
  postalCode: Yup.string()
    .required('Postal code is required')
    .test('is-digit', 'Postal code may only contain digits', (value) =>
      /\d/.test(value || '')
    )
    .min(5, 'Postal code must have 5 digits')
    .max(5, 'Postal code must have 5 digits'),
  state: Yup.string().required('State is required'),
  country: Yup.string().required('Country is required'),
});

export const PAYPAL_EMAIL_VALIDATOR = Yup.string()
  .email('Invalid email')
  .required('A PayPal email is required for payment');

export const SOCIALS_VALIDATOR = Yup.object().shape({
  instagram: Yup.string().optional(),
  tikTok: Yup.string().optional(),
  youTube: Yup.string()
    .test(
      'test-valid-website',
      'Please enter a valid URL',
      (value) => typeof value === 'undefined' || isURL(value)
    )
    .optional(),
  website: Yup.string()
    .test(
      'test-valid-website',
      'Please enter a valid URL',
      (value) => typeof value === 'undefined' || isURL(value)
    )
    .optional(),
});

export const CREATOR_CATEGORIES_VALIDATOR = Yup.object().required();

export const CREATOR_SKILLS_VALIDATOR = Yup.object().required();
