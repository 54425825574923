// https://www.geeksforgeeks.org/check-if-two-arrays-are-equal-or-not/
export const arraysAreEqual = (arr1: any[], arr2: any[]) => {
  // Length of the two array
  const n = arr1.length;
  const m = arr2.length;

  // If lengths of arrays are not equal
  if (n != m) return false;

  // To store xor of both arrays
  let b1 = arr1[0];
  let b2 = arr2[0];

  // Find xor of each elements in array
  for (let i = 1; i < n; i++) {
    b1 ^= arr1[i];
  }
  for (let i = 1; i < m; i++) {
    b2 ^= arr2[i];
  }
  const allXor = b1 ^ b2;

  // If xor is zero means they are
  // equal (5^5=0)
  if (allXor == 0) return true;

  // If all elements were not same,
  // then xor will not be zero
  return false;
};

export const objectFlip = (obj: Record<string, string | number>) => {
  return Object.keys(obj).reduce((ret, key) => {
    ret[obj[key]] = key;
    return ret;
  }, {} as Record<string, string>);
};
export const arraysHaveMatch = (arr1: any[], arr2: any[]) =>
  arr1.some((r) => arr2.includes(r));

export const alternateArrays = <T>(arr1: T[], arr2: T[]) => {
  const result: T[] = [];
  const minLength = Math.min(arr1.length, arr2.length);
  for (let i = 0; i < minLength; i++) {
    result.push(arr1[i], arr2[i]);
  }
  result.push(...arr1.slice(minLength), ...arr2.slice(minLength));
  return result;
};
