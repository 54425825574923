import { CreatorCategory, CreatorSkill } from 'types/creator';
import { isGender, RegistrationForm } from 'types/creator';
import * as Yup from 'yup';
import { Restrictions } from '@uppy/core/types';
import { mbToBytes } from 'utils/file';
import {
  ADDRESS_VALIDATOR,
  AGE_RANGE_VALIDATOR,
  CREATOR_CATEGORIES_VALIDATOR,
  CREATOR_SKILLS_VALIDATOR,
  PAYPAL_EMAIL_VALIDATOR,
  SOCIALS_VALIDATOR,
} from './validation';
import { LabelTagArray } from 'types/common';

export interface RegistrationFormStepProps {
  handleNextStep: (outsideUS?: boolean) => void;
}

const maxNumberOfFiles = 6;

export const UploadContentRestrictions: Restrictions = {
  allowedFileTypes: ['image/*', 'video/*'],
  maxFileSize: mbToBytes(150),
  maxNumberOfFiles,
};

export type RegistrationFormikValues = Omit<RegistrationForm, '_id'>;

export const initialRegistrationFormValues: RegistrationFormikValues = {
  jobTypes: {
    content: false,
    social: false,
  },
  profilePicture: '',
  firstName: '',
  lastName: '',
  gender: undefined,
  ageRange: undefined,
  address: {
    line1: '',
    line2: '',
    city: '',
    postalCode: '',
    state: '',
    country: '',
  },
  payPalEmail: '',

  socials: {
    instagram: '',
    tikTok: '',
    youTube: '',
    website: '',
  },
  portfolio: [],
  phoneNumber: '',
  creatorCategories: {},
  creatorSkills: {},
};

/**
 * TODO: Make re-useable validator patterns
 * - Address schema
 * - Social schema
 *  */
export const RegistrationValidationSchema = Yup.object().shape({
  jobTypes: Yup.object().shape({
    content: Yup.boolean().required(),
    social: Yup.boolean().required(),
  }),
  profilePicture: Yup.string().required(),
  firstName: Yup.string().max(50).required('First name is required'),
  lastName: Yup.string().max(50).required('Last name is required'),
  gender: Yup.string()
    .required('Gender is required')
    .test('is-gender', 'Invalid Gender Selected', (value) => isGender(value)),
  ageRange: AGE_RANGE_VALIDATOR,
  address: ADDRESS_VALIDATOR,
  payPalEmail: PAYPAL_EMAIL_VALIDATOR,
  socials: SOCIALS_VALIDATOR,
  portfolio: Yup.array().max(maxNumberOfFiles).required(),
  creatorCategories: CREATOR_CATEGORIES_VALIDATOR,
  creatorSkills: CREATOR_SKILLS_VALIDATOR,
});

export const CREATOR_SKILLS = [
  'adobeIllustrator', // Adobe Illustrator
  'adobePhotoshop', // Adobe Photoshop
  'afterEffects', // After Effects
  'gifCreation', // Gif Creation
  'graphicDesign', // Graphic Design
  'illustration', // Illustration
  'lifestylePhotography', // Lifestyle Photography
  'productPhotography', // Product Photography
  'studioPhotography', // Studio Photography
  'lighting', // Lighting
  'musicProduction', // Music Production
  'socialMediaContentProduction', // Social Media Content Production
  'testimonialVideos', // Testimonial Videos
  'videoEditing', // Video Editing
] as const;

export const CREATOR_SKILL_TAGS: LabelTagArray<CreatorSkill> = [
  {
    id: 'adobeIllustrator',
    label: ' Adobe Illustrator',
  },
  { id: 'adobePhotoshop', label: 'Adobe Photoshop' },
  { id: 'afterEffects', label: 'After Effects' },
  { id: 'gifCreation', label: 'Gif Creation' },
  { id: 'graphicDesign', label: 'Graphic Design' },
  { id: 'illustration', label: 'Illustration' },
  {
    id: 'lifestylePhotography',
    label: 'Lifestyle Photography',
  },
  {
    id: 'productPhotography',
    label: 'Product Photography',
  },
  {
    id: 'studioPhotography',
    label: 'Studio Photography',
  },
  { id: 'lighting', label: 'Lighting' },
  {
    id: 'musicProduction',
    label: 'Music Production',
  },
  {
    id: 'socialMediaContentProduction',
    label: 'Social Media Content Production',
  },
  {
    id: 'testimonialVideos',
    label: 'Testimonial Videos',
  },
  { id: 'videoEditing', label: ' Video Editing' },
];

export const CREATOR_CATEGORIES = [
  'beauty', // Beauty
  'comedy', // Comedy
  'designArt', // Design / Art
  'diy', // DIY
  'family', // Family
  'fashion', // Fashion
  'cooking', // Food / Cooking
  'gaming', // Gaming
  'healthFitness', // Health & Fitness
  'learningEducation', // Learning / Education
  'lifestyle', // Lifestyle
  'musicDance', // Music and Dance
  'photography', // Photography
  'sports', // Sports
  'tech', // Tech
  'travelAdventure', // Travel and Adventure
  'vlogging', // Vlogging
  'wellness', // Wellness
] as const;

export const CREATOR_CATEGORY_TAGS: LabelTagArray<CreatorCategory> = [
  { id: 'beauty', label: 'Beauty' },
  { id: 'comedy', label: 'Comedy' },
  { id: 'designArt', label: 'Design / Art' },
  { id: 'diy', label: 'DIY' },
  { id: 'family', label: 'Family' },
  { id: 'fashion', label: 'Fashion' },
  { id: 'cooking', label: 'Food / Cooking' },
  { id: 'gaming', label: 'Gaming' },
  { id: 'healthFitness', label: 'Health & Fitness' },
  {
    id: 'learningEducation',
    label: 'Learning / Education',
  },
  { id: 'lifestyle', label: 'Lifestyle' },
  { id: 'musicDance', label: 'Music and Dance' },
  { id: 'photography', label: 'Photography' },
  { id: 'sports', label: 'Sports' },
  { id: 'tech', label: 'Tech' },
  {
    id: 'travelAdventure',
    label: 'Travel and Adventure',
  },
  { id: 'vlogging', label: 'Vlogging' },
  { id: 'wellness', label: 'Wellness' },
];

export enum SOCIAL_PROVIDERS {
  INSTAGRAM = 'instagram',
  TIK_TOK = 'tikTok',
  YOU_TUBE = 'youTube',
  WEBSITE = 'website',
}

export const TREND_VIDEOS = {
  WELCOME_VIDEO:
    'https://www.youtube.com/embed/KxBtZ8hI4Ms?modestbranding=1&rel=0&autoplay=1&mute=1',
  APPLICATION_APPROVED:
    'https://www.youtube.com/embed/5YlHIpqpbI0?modestbranding=1&rel=0&autoplay=1',
  APPLICATION_UNDER_REVIEW:
    'https://www.youtube.com/embed/8_lbTO4OW9o?modestbranding=1&rel=0&autoplay=1',
  APPLICATION_NOT_APPROVED:
    'https://www.youtube.com/embed/xrIPyyoCnUE?modestbranding=1&rel=0&autoplay=1',
};
